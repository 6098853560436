import axios from 'axios'
import { returnData, config, catchError } from './api-utils'

const getDashboards = (companyCode) =>
  axios
    .get(`${companyCode ? companyCode : ''}/dashboards`, config())
    .then(returnData)
    .catch(catchError)

const createDashboard = (dashboard) =>
  axios
    .post('/dashboards', dashboard, config())
    .then(returnData)
    .catch(catchError)

const createDashboardWidget = ({ dashboardId, widget }) =>
  axios
    .post(`/dashboards/${dashboardId}/widget`, widget, config())
    .then(returnData)
    .catch(catchError)

const copyDashboard = ({ dashboardType, copyDashboardShares, id, name }) =>
  axios
    .post(
      `/dashboards/${id}/copy`,
      { dashboardType, copyDashboardShares, name },
      config()
    )
    .then(returnData)
    .catch(catchError)

const patchDashboard = ({ dashboardId, patch }) =>
  axios
    .patch(`/dashboards/${dashboardId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const deleteDashboard = ({ dashboardId }) =>
  axios
    .delete(`/dashboards/${dashboardId}`, config())
    .then(returnData)
    .catch(catchError)

const getDashboardShares = (dashboardId) =>
  axios
    .get(`/dashboards/${dashboardId}/share`, config())
    .then(returnData)
    .catch(catchError)

const createDashboardShare = ({ dashboardId, userIds }) =>
  axios
    .post(`/dashboards/${dashboardId}/share`, userIds, config())
    .then(returnData)
    .catch(catchError)

const deleteDashboardShare = ({ dashboardId, userIds }) =>
  axios
    .post(`/dashboards/${dashboardId}/share/bulkdelete`, userIds, config())
    .then(returnData)
    .catch(catchError)

const deleteDashboardWidget = ({ dashboardId, widgetId }) =>
  axios
    .delete(`/dashboards/${dashboardId}/widget/${widgetId}`, config())
    .then(returnData)
    .catch(catchError)

const addWidget = ({ dashboardId, widgetToAdd }) =>
  axios
    .post(`/dashboards/${dashboardId}/widget`, widgetToAdd, config())
    .then(returnData)
    .catch(catchError)

const deleteWidget = ({ dashboardId, widgetId }) =>
  axios
    .delete(`/dashboards/${dashboardId}/widget/${widgetId}`, config())
    .then(returnData)
    .catch(catchError)

const patchDashboardWidget = ({ dashboardId, patch, widgetId }) =>
  axios
    .patch(`/dashboards/${dashboardId}/widget/${widgetId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const upsertWidgetParameters = ({ dashboardId, widgetId, parameters }) =>
  axios
    .post(
      `/dashboards/${dashboardId}/widget/${widgetId}/parameters`,
      parameters,
      config()
    )
    .then(returnData)
    .catch(catchError)

const dashboardApi = {
  addWidget,
  createDashboard,
  createDashboardShare,
  createDashboardWidget,
  copyDashboard,
  deleteDashboard,
  deleteDashboardShare,
  deleteDashboardWidget,
  deleteWidget,
  getDashboards,
  getDashboardShares,
  patchDashboard,
  patchDashboardWidget,
  upsertWidgetParameters,
}

export default dashboardApi
