export const CREATE_DASHBOARD = 'app/Dashboards/CREATE_DASHBOARD'
export const CREATE_DASHBOARD_ERROR = 'app/Dashboards/CREATE_DASHBOARD_ERROR'
export const CREATE_DASHBOARD_SUCCESS =
  'app/Dashboards/CREATE_DASHBOARD_SUCCESS'

export const CREATE_DASHBOARD_SHARE = 'app/Dashboards/CREATE_DASHBOARD_SHARE'
export const CREATE_DASHBOARD_SHARE_ERROR =
  'app/Dashboards/CREATE_DASHBOARD_SHARE_ERROR'
export const CREATE_DASHBOARD_SHARE_SUCCESS =
  'app/Dashboards/CREATE_DASHBOARD_SHARE_SUCCESS'

export const COPY_DASHBOARD = 'app/Dashboards/COPY_DASHBOARD'
export const COPY_DASHBOARD_ERROR = 'app/Dashboards/COPY_DASHBOARD_ERROR'
export const COPY_DASHBOARD_SUCCESS = 'app/Dashboards/COPY_DASHBOARD_SUCCESS'

export const COPY_DASHBOARD_WIDGET = 'app/Dashboards/COPY_DASHBOARD_WIDGET'
export const COPY_DASHBOARD_WIDGET_ERROR =
  'app/Dashboards/COPY_DASHBOARD_WIDGET_ERROR'
export const COPY_DASHBOARD_WIDGET_SUCCESS =
  'app/Dashboards/COPY_DASHBOARD_WIDGET_SUCCESS'

export const CREATE_DASHBOARD_WIDGET = 'app/Dashboards/CREATE_DASHBOARD_WIDGET'
export const CREATE_DASHBOARD_WIDGET_ERROR =
  'app/Dashboards/CREATE_DASHBOARD_WIDGET_ERROR'
export const CREATE_DASHBOARD_WIDGET_SUCCESS =
  'app/Dashboards/CREATE_DASHBOARD_WIDGET_SUCCESS'

export const DELETE_DASHBOARD = 'app/Dashboards/DELETE_DASHBOARD'
export const DELETE_DASHBOARD_ERROR = 'app/Dashboards/DELETE_DASHBOARD_ERROR'
export const DELETE_DASHBOARD_SUCCESS =
  'app/Dashboards/DELETE_DASHBOARD_SUCCESS'

export const DELETE_DASHBOARD_SHARE = 'app/Dashboards/DELETE_DASHBOARD_SHARE'
export const DELETE_DASHBOARD_SHARE_ERROR =
  'app/Dashboards/DELETE_DASHBOARD_SHARE_ERROR'
export const DELETE_DASHBOARD_SHARE_SUCCESS =
  'app/Dashboards/DELETE_DASHBOARD_SHARE_SUCCESS'

export const DELETE_DASHBOARD_WIDGET = 'app/Dashboards/DELETE_DASHBOARD_WIDGET'
export const DELETE_DASHBOARD_WIDGET_ERROR =
  'app/Dashboards/DELETE_DASHBOARD_WIDGET_ERROR'
export const DELETE_DASHBOARD_WIDGET_SUCCESS =
  'app/Dashboards/DELETE_DASHBOARD_WIDGET_SUCCESS'

export const GET_DASHBOARDS = 'app/Dashboards/GET_DASHBOARDS'
export const GET_DASHBOARDS_ERROR = 'app/Dashboards/GET_DASHBOARDS_ERROR'
export const GET_DASHBOARDS_SUCCESS = 'app/Dashboards/GET_DASHBOARDS_SUCCESS'

export const GET_DASHBOARD_SHARES = 'app/Dashboards/GET_DASHBOARD_SHARES'
export const GET_DASHBOARD_SHARES_ERROR =
  'app/Dashboards/GET_DASHBOARD_SHARES_ERROR'
export const GET_DASHBOARD_SHARES_SUCCESS =
  'app/Dashboards/GET_DASHBOARD_SHARES_SUCCESS'

export const UPDATE_DASHBOARD = 'app/Dashboards/UPDATE_DASHBOARD'
export const UPDATE_DASHBOARD_ERROR = 'app/Dashboards/UPDATE_DASHBOARD_ERROR'
export const UPDATE_DASHBOARD_SUCCESS =
  'app/Dashboards/UPDATE_DASHBOARD_SUCCESS'

export const UPDATE_DASHBOARD_SHARES = 'app/Dashboards/UPDATE_DASHBOARD_SHARES'
export const UPDATE_DASHBOARD_SHARES_ERROR =
  'app/Dashboards/UPDATE_DASHBOARD_SHARES_ERROR'
export const UPDATE_DASHBOARD_SHARES_SUCCESS =
  'app/Dashboards/UPDATE_DASHBOARD_SHARES_SUCCESS'

export const UPDATE_DASHBOARD_WIDGET = 'app/Dashboards/UPDATE_DASHBOARD_WIDGET'
export const UPDATE_DASHBOARD_WIDGET_ERROR =
  'app/Dashboards/UPDATE_DASHBOARD_WIDGET_ERROR'
export const UPDATE_DASHBOARD_WIDGET_SUCCESS =
  'app/Dashboards/UPDATE_DASHBOARD_WIDGET_SUCCESS'

export const UPDATE_DASHBOARD_WIDGET_PARAMETERS =
  'app/Dashboards/UPDATE_DASHBOARD_WIDGET_PARAMETERS'
export const UPDATE_DASHBOARD_WIDGET_PARAMETERS_ERROR =
  'app/Dashboards/UPDATE_DASHBOARD_WIDGET_PARAMETERS_ERROR'
export const UPDATE_DASHBOARD_WIDGET_PARAMETERS_SUCCESS =
  'app/Dashboards/UPDATE_DASHBOARD_WIDGET_PARAMETERS_SUCCESS'

export const DASHBOARD_GROUP_ORDER_STORAGE_KEY =
  'finazilla_dashboard_group_order'
export const DASHBOARD_EXPANDED_GROUPS_STORAGE_KEY =
  'finazilla_expanded_dashboard_group_ids'

export const CUSTOMER_DASHBOARD = 'Customer'
export const SYSTEM_DASHBOARD = 'System'
export const USER_DASHBOARD = 'User'

export const DASHBOARD_TYPES = [
  USER_DASHBOARD,
  CUSTOMER_DASHBOARD,
  SYSTEM_DASHBOARD,
]
